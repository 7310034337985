import { FC } from 'react';
import { DropdownFormControl, SelectControl } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeSponsor, selectDropdown } from 'api/redux/DropdownReducer';
import { InputLabel, MenuItem } from '@mui/material';
import { onSetSelectedDocTypeIds } from 'api/redux/DocumentsReducer';

export const SponsorDropown: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor, availableSponsors } = grants.grants;
	const dispatch = useDispatch();

	return (
		<DropdownFormControl
			variant="outlined"
			size="small"
			style={{ margin: 5, width: '200px' }}
			title={currentSponsor.name}
		>
			<InputLabel id="sponsor-selector" style={{ color: '#F1F2F9' }}>
				Sponsor
			</InputLabel>
			<SelectControl
				labelId="sponsor-selector"
				id="sponsor-selector"
				value={currentSponsor.id}
				label="Sponsor"
			>
				{availableSponsors.map((org, index) => (
					<MenuItem
						onClick={() => {
							dispatch(onChangeSponsor(org));
							dispatch(onSetSelectedDocTypeIds([]));
						}}
						key={index}
						value={org.id}
					>
						{org.name}
					</MenuItem>
				))}
			</SelectControl>
		</DropdownFormControl>
	);
};
