import { selectDropdown } from 'api/redux/DropdownReducer';
import { useLazyGetLightLogoQuery } from 'api/redux/services/organizations.service';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SponsorDropown } from './dropdowns/SponsorDropdown';
import { LogoSponsorDropdown } from './dropdowns/LogoSponsorDropdown';

export const SponsorLogo: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const [fetchLightLogo, { data: logo }] = useLazyGetLightLogoQuery();

	useEffect(() => {
		if (currentSponsor.id === 0) return;

		fetchLightLogo(currentSponsor.id);
	}, [currentSponsor]);

	if (!logo?.url) return <SponsorDropown />;

	return <LogoSponsorDropdown url={logo?.url} />;
};
