import { FC, useEffect, useState } from 'react';
import { pdfjs } from 'react-pdf';
import {
	useLazyGetFundDocumentPdfQuery,
	useLazyGetInvestorDocumentPdfQuery,
} from 'api/redux/services/documentApi';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Box, Grid, Paper } from '@mui/material';
import { DocumentTableKeys, IDocumentPreviewProps } from './types';
import { ButtonsRow } from './ButtonsRow';
import { FramedDocument } from './FramedDocument';

export const DocumentPreview: FC<IDocumentPreviewProps> = ({
	document,
	tableKey,
}) => {
	const [getInvestorDocPdf, { data: investorDocPdf }] =
		useLazyGetInvestorDocumentPdfQuery();
	const [getFundDocPdf, { data: fundDocPdf }] =
		useLazyGetFundDocumentPdfQuery();
	const [pdfUrl, setPdfUrl] = useState('');
	const pdfBlob =
		tableKey === DocumentTableKeys.FT ? fundDocPdf : investorDocPdf;

	useEffect(() => {
		if (tableKey === DocumentTableKeys.FT) {
			getFundDocPdf(document.id);
			return;
		}

		getInvestorDocPdf(document.id);
	}, []);

	useEffect(() => {
		if (pdfBlob === undefined) return;

		const tmpUrl = URL.createObjectURL(pdfBlob);
		setPdfUrl(tmpUrl);

		return () => URL.revokeObjectURL(tmpUrl);
	}, [fundDocPdf, investorDocPdf]);

	if (pdfBlob === undefined) return <></>;

	return (
		<Box
			sx={{
				display: 'flex',
				height: '100%',
				width: '100%',
				justifyContent: 'center',
			}}
		>
			<Paper elevation={0}>
				<Grid
					container
					direction="column"
					sx={{
						height: '100%',
						padding: '5px',
						borderRadius: '5px',
						border: '1px solid #D0D7DE',
					}}
				>
					<Grid
						item
						xs={11}
						sx={{
							overflowX: 'auto',
							overflowY: 'auto',
							maxWidth: '-webkit-fill-available !important',
						}}
					>
						<FramedDocument pdfBlob={pdfBlob} />
					</Grid>
					<Grid
						item
						xs={1}
						sx={{
							display: 'flex',
							justifyContent: 'flex-start',
						}}
					>
						<ButtonsRow
							document={document}
							tableKey={tableKey}
							docUrl={pdfUrl}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};
