import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import {
	useLazyGetOrganizationQuery,
	useLazyGetOrganizationUsersQuery,
} from 'api/redux/services/organizations.service';
import { UsersTable } from '../../common/components/UsersTable/UsersTable';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectNavbarTablet } from 'api/redux/NavBarStateReducer';
import { onSetUsers, usersState } from 'api/redux/UsersReducer';
import { RoleLevels } from 'common/helpers/permissions/Roles';
import { styled } from '@mui/system';

const OrgMemberPaper = styled(Paper)(() => ({
	width: 'min(100%, 1300px)',
	marginLeft: 'auto',
	marginRight: 'auto',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#FFFFFF',
	borderRadius: '5px',
}));

const OrganizationMembers: FC = () => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const tablet = useSelector(selectNavbarTablet);
	const [getOrganizationUsers, organizationUsers] =
		useLazyGetOrganizationUsersQuery();
	const [getOrganization, organization] = useLazyGetOrganizationQuery();
	const [page, setPage] = useState<number>(0);
	const { users, sortStrategy } = useSelector(usersState);
	const [pages, setPages] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>('');

	const fetchUsers = () => {
		getOrganizationUsers({
			sponsorId: currentSponsor.id,
			page,
			searchString,
			sortStrategy: sortStrategy,
			roleLevel: RoleLevels.SPONSOR,
		});
	};

	useEffect(() => {
		if (currentSponsor.id !== 0) fetchUsers();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSponsor, page, searchString, sortStrategy]);

	useEffect(() => {
		if (organizationUsers.data) {
			const { rows: fetchedUsers, pages } = organizationUsers.data;
			dispatch(onSetUsers(fetchedUsers));
			setPages(pages);
		}
	}, [dispatch, organizationUsers.data]);

	useEffect(() => {
		const { isFetching } = organizationUsers;

		if (isFetching) {
			setLoading(true);
		}
	}, [organizationUsers]);

	useEffect(() => {
		const { isSuccess, isError } = organizationUsers;

		if (isSuccess || isError) {
			setLoading(false);
		}
	}, [organizationUsers]);

	useEffect(() => {
		getOrganization(currentSponsor.id);
	}, [currentSponsor.id, getOrganization]);

	if (!users) return <></>;

	return (
		<OrgMemberPaper
			elevation={0}
			sx={{ paddingLeft: `${tablet ? '0px' : '55px'}` }}
		>
			<UsersTable
				refetchUsers={fetchUsers}
				page={page + 1}
				setPage={setPage}
				pages={pages}
				loading={loading}
				searchString={searchString}
				setSearchString={setSearchString}
			/>
		</OrgMemberPaper>
	);
};

export default OrganizationMembers;
