import {
	IFundGrant,
	IInvestorGrant,
	ISponsorGrant,
	IUserWithInvestors,
} from '@services/userApi';
import { DocumentType } from 'app/types/documentTypes.types';
import { IDocumentResponse } from './services/documentApi';
import { ROLES } from 'common/helpers/permissions/Roles';

export const emptyInvestor: IInvestorGrant = {
	id: 0,
	name: '',
	role: ROLES.INVESTOR_USER,
};

export const emptySponsor: ISponsorGrant = {
	id: 0,
	name: '',
	funds: [],
	role: ROLES.SPONSOR_USER,
};

export const emptyFund: IFundGrant = {
	id: 0,
	name: '',
	investors: [],
};

export enum DropDownOrderValues {
	FUND = 'fund',
	INVESTOR = 'investor',
}

export interface GrantState {
	grants: {
		currentSponsor: ISponsorGrant;
		currentFund: IFundGrant;
		currentInvestor: IInvestorGrant;
		availableSponsors: ISponsorGrant[];
		availableFunds: IFundGrant[];
		availableInvestors: IInvestorGrant[];
		allAvailableInvestors: IInvestorGrant[];
		firstDropdown: DropDownOrderValues;
	};
	showAllUsers: boolean;
}

export interface IObjectWithName {
	name: string;
}

export interface DocsState {
	allDocs: IDocumentResponse[];
	availableDocTypes: DocumentType[];
	userAllowedDocTypes: DocumentType[];
	selectedDocTypeIds: number[];
	sortStrategy: ISortStrategy[];
}

export interface UsersState {
	users: IUserWithInvestors[];
	sortStrategy: ISortStrategy[];
}

export interface ISimpleGrant {
	sponsor?: ISponsorGrant;
	fund?: IFundGrant;
	investor?: IInvestorGrant;
	firstDropdown: DropDownOrderValues;
}

export enum IAscDesc {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface ISortStrategy {
	column: string;
	ascdesc: IAscDesc;
}
export interface GrantUpdates {
	currentSponsor?: ISponsorGrant;
	currentFund?: IFundGrant;
	currentInvestor?: IInvestorGrant;
	availableSponsors?: ISponsorGrant[];
	availableFunds?: IFundGrant[];
	availableInvestors?: IInvestorGrant[];
	allAvailableInvestors?: IInvestorGrant[];
	firstDropdown?: DropDownOrderValues;
}

export interface FundChangeParams {
	selectedFund: IFundGrant;
	currentState: GrantState['grants'];
	emptyInvestor: IInvestorGrant;
}

export interface InvestorChangeParams {
	selectedInvestor: IInvestorGrant;
	currentState: GrantState['grants'];
	emptyFund: IFundGrant;
}

export interface UserGrantsChangeParams {
	userGrants: ISponsorGrant[];
	loadCookies: boolean;
	emptyInvestor: IInvestorGrant;
}

export interface ShowAllUsersParams {
	showAll: boolean;
	currentState: GrantState['grants'];
}

export interface InitialSponsorStateParams {
	sponsor: ISponsorGrant;
	emptyFund: IFundGrant;
	emptyInvestor: IInvestorGrant;
	firstDropdown: DropDownOrderValues;
}
