import { FC, useEffect, useState, useMemo } from 'react';
import printJS from 'print-js-safari';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import CardsTable from 'common/components/CardsTable/CardsTable';
import { DocumentFilters } from './DocumentFilters';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { Document } from '../../app/types/document.types';
import {
	DocumentTableKeys,
	DocumentTables,
	IDocumentToDelete,
	TDocumentsListProps,
	documentTableKeys,
} from './types';
import { DeleteDocumentModal } from './DeleteDocumentsModal';
import {
	IDocumentResponse,
	useDeleteDocumentMutation,
	useLazyGetFundDocumentPdfQuery,
	useLazyGetInvestorDocumentPdfQuery,
} from 'api/redux/services/documentApi';
import { useDispatch, useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import {
	selectNavbarMobile,
	selectNavbarTablet,
} from 'api/redux/NavBarStateReducer';
import { useNavigate } from 'react-router-dom';
import {
	Alert,
	Box,
	Grid,
	Pagination,
	Snackbar,
	Typography,
} from '@mui/material';
import { IconBtn } from 'common/components/IconBtn';
import {
	ContentCopy,
	Delete,
	GetApp,
	VisibilityOutlined,
	Print,
} from '@mui/icons-material';
import {
	specificDocumentUrl,
	getDate,
	formatDate,
	downloadFile,
	urlForWaterMarkedDoc,
	printWatermarkedDoc,
} from './utils';
import { fileName } from './utils';
import { documentsState, onSetSortStrategy } from 'api/redux/DocumentsReducer';
import {
	UserInvestorPermissionOptions,
	UserInvestorPermissionsWrapper,
} from 'common/helpers/permissions/UserInvestorPermissionsWrapper';
import { IAscDesc } from 'api/redux/types';
import {
	useLazyGetOrganizationQuery,
	useLazyGetWatermarkDocTypesQuery,
} from 'api/redux/services/organizations.service';
import { DocumentType } from 'app/types/documentTypes.types';
import { ESortSource } from 'common/components/CardsTable/types';
import { styled } from '@mui/system';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { NoDocuments } from './NoDocuments';

const RootGrid = styled(Grid)(() => ({
	width: '100%',
	height: '100%',
	paddingRight: 5,
	paddingLeft: 5,
	paddingTop: 5,
}));

export const DocumentsList: FC<TDocumentsListProps> = ({
	onRefresh,
	pages,
	page,
	setPage,
}) => {
	const { user } = useGrants();
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const tablet = useSelector(selectNavbarTablet);
	const mobile = useSelector(selectNavbarMobile);
	const navigate = useNavigate();
	const { currentFund, currentSponsor, currentInvestor } = grants.grants;
	const [deleteDocument] = useDeleteDocumentMutation();
	const [getInvestorDocPdf] = useLazyGetInvestorDocumentPdfQuery();
	const [getFundDocPdf] = useLazyGetFundDocumentPdfQuery();
	const [documentToDelete, setDocumentToDelete] = useState<
		IDocumentToDelete | undefined
	>(undefined);
	const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
	const { allDocs, selectedDocTypeIds } = useSelector(documentsState);
	const [showDocUrlCopied, setShowDocUrlCopied] = useState<boolean>(false);
	const [docName, setDocName] = useState<string>('');
	const [docToDownload, setDocToDownload] = useState<Document | undefined>(
		undefined,
	);
	const [docToPrint, setDocToPrint] = useState<Document | undefined>(undefined);
	const [documents, setDocuments] = useState<IDocumentResponse[]>([]);
	const [fetchOrganization, sponsor] = useLazyGetOrganizationQuery();
	const [fetchWatermarkDocTypes, watermarkDocTypes] =
		useLazyGetWatermarkDocTypesQuery();
	const [allowedWatermarkDocTypes, setAllowedWatermarkDocTypes] = useState<
		DocumentType[]
	>([]);
	const allowedDocTypeForWatermark = (document: Document) => {
		return allowedWatermarkDocTypes
			.map((dt) => dt.id)
			.includes(document.documentTypeId);
	};

	useEffect(() => {
		setDocuments(
			allDocs.filter((doc: IDocumentResponse) =>
				selectedDocTypeIds.includes(doc.documentTypeId),
			),
		);
	}, [allDocs, selectedDocTypeIds]);

	useEffect(() => {
		if (currentSponsor.id === 0) return;

		fetchOrganization(currentSponsor.id);
		fetchWatermarkDocTypes(currentSponsor.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSponsor]);

	useEffect(() => {
		if (!watermarkDocTypes.data) return;

		setAllowedWatermarkDocTypes(watermarkDocTypes.data);
	}, [watermarkDocTypes.data]);

	const fetchPDFDoc = async (document: Document) => {
		const level: DocumentTableKeys =
			documentTableKeys[document.table as DocumentTables];

		if (level === DocumentTableKeys.FT) {
			return await getFundDocPdf(document.id);
		}

		return await getInvestorDocPdf(document.id);
	};

	const handleDelete = (document: Document) => {
		setDocumentToDelete({
			id: document.id,
			table: document.table as DocumentTables,
		});
		setDeleteOpen(true);
	};

	const handleDeleteConfirm = async () => {
		if (!documentToDelete) return;

		await deleteDocument({
			documentId: documentToDelete.id,
			documentTable: documentToDelete.table,
		});
		onRefresh();
		setDeleteOpen(false);
	};

	const handleSort = (column: string, ascdesc: IAscDesc) => {
		const sortableColumns: string[] = ['type', 'period', 'createdAt'];

		if (!sortableColumns.includes(column)) return;

		dispatch(onSetSortStrategy({ column, ascdesc }));
	};

	const columns = useMemo(
		() => [
			{
				Header: 'Fund',
				id: 'fund',
				accessor: 'fund',
				width: 70,
				Cell: () => currentFund.name,
				isVisible: !mobile,
			},
			{
				Header: 'Investor',
				id: 'investor',
				accessor: 'investor',
				width: 130,
				Cell: ({ row }) => {
					const possibleValues = {
						[DocumentTables.FUND_DOCUMENTS]: <Typography>-</Typography>,
						[DocumentTables.INVESTOR_DOCUMENTS]: row.original.investorName || (
							<Typography>-</Typography>
						),
					};

					return possibleValues[row.original.table];
				},
				isVisible: !mobile,
			},
			{
				accessor: 'type',
				id: 'type',
				Header: 'Type',
				width: mobile ? 150 : 130,
			},
			{
				Header: 'Document Date',
				accessor: (row) => {
					return getDate(row.period);
				},
				id: 'period',
				width: mobile ? 150 : 90,
				isSorted: true,
				Cell: ({ row }) => {
					const docDate = (period?: string) => {
						if (!period) return '';

						let stringDate = period;

						if (stringDate.length > 10)
							stringDate = stringDate.substring(0, 10);

						return formatDate(stringDate);
					};

					return formatDate(docDate(row.original.period));
				},
			},
			{
				Header: 'Date Uploaded',
				accessor: (row) => {
					return getDate(row.createdAt);
				},
				id: 'createdAt',
				width: mobile ? 150 : 90,
				isSorted: true,
				Cell: ({ row }) => {
					return formatDate(row.original.createdAt);
				},
			},
			{
				Header: '',
				accessor: 'actions',
				id: 'actions',
				Cell: ({ row }) => {
					const doc: Document = row.original;
					return (
						<div style={{ padding: '5px', textAlign: 'end' }}>
							<IconBtn
								customColor={true}
								onClick={(event) => {
									event.stopPropagation();
									redirectToSpecificDocument(doc);
								}}
								tooltip="View document"
							>
								<VisibilityOutlined sx={{ width: '24px', height: '24px' }} />
							</IconBtn>

							<UserInvestorPermissionsWrapper
								ability={UserInvestorPermissionOptions.canDownload}
							>
								<IconBtn
									customColor={true}
									onClick={(event) => {
										event.stopPropagation();
										setDocToDownload(doc);
										setDocName(fileName(doc));
									}}
									tooltip="Download document"
								>
									<GetApp sx={{ width: '24px', height: '24px' }} />
								</IconBtn>
							</UserInvestorPermissionsWrapper>

							<UserInvestorPermissionsWrapper
								ability={UserInvestorPermissionOptions.canPrint}
							>
								<IconBtn
									onClick={(event) => {
										event.stopPropagation();
										setDocToPrint(doc);
									}}
									tooltip="Print Document"
									customColor={true}
								>
									<Print sx={{ width: '24px', height: '24px' }} />
								</IconBtn>
							</UserInvestorPermissionsWrapper>

							<IconBtn
								onClick={(event) => {
									navigator.clipboard.writeText(
										specificDocumentUrl({
											document: doc,
											withBase: true,
											tableKey:
												documentTableKeys[doc.table as DocumentTableKeys],
											investorId: currentInvestor.id,
										}),
									);
									event.stopPropagation();
									setShowDocUrlCopied(true);
								}}
								customColor={true}
								tooltip="Copy document URL"
							>
								<ContentCopy sx={{ width: '24px', height: '24px' }} />
							</IconBtn>

							<PermissionsWrapper scopes={[SCOPES.canDeleteDocument]}>
								<IconBtn
									customColor={true}
									onClick={(event) => {
										event.stopPropagation();
										handleDelete(doc);
									}}
									tooltip="Delete Document"
								>
									<Delete sx={{ width: '24px', height: '24px' }} />
								</IconBtn>
							</PermissionsWrapper>
						</div>
					);
				},
				isVisible: !mobile,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentFund.name],
	);

	/** Executed everytime a file is downloaded */
	useEffect(() => {
		if (!docToDownload) return;

		fetchPDFDoc(docToDownload).then(async (response) => {
			const pdfBlob = response.data;
			if (pdfBlob === undefined) return;

			let fileUrl = URL.createObjectURL(pdfBlob);

			if (
				sponsor.data?.watermarkPdf &&
				allowedDocTypeForWatermark(docToDownload)
			) {
				fileUrl = await urlForWaterMarkedDoc(fileUrl, user);
			}

			downloadFile({ fileUrl, fileName: docName });
			setDocToDownload(undefined);
			setDocName('');
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sponsor.data, docToDownload]);

	useEffect(() => {
		if (!docToPrint) return;

		fetchPDFDoc(docToPrint).then((response) => {
			const pdfBlob = response.data;
			if (pdfBlob === undefined) return;

			const pdfDocUrl = URL.createObjectURL(pdfBlob);

			if (
				!sponsor.data?.watermarkPdf ||
				!allowedDocTypeForWatermark(docToPrint)
			) {
				printJS({ printable: pdfDocUrl });
				return;
			}

			if (pdfDocUrl) printWatermarkedDoc(pdfDocUrl, user);
			setDocToPrint(undefined);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sponsor.data, docToPrint]);

	const redirectToSpecificDocument = (document: Document) =>
		navigate(
			specificDocumentUrl({
				document,
				withBase: false,
				tableKey: documentTableKeys[document.table as DocumentTableKeys],
				investorId: currentInvestor.id,
			}),
		);

	const initiateDocumentDownload = (document: Document) => {
		setDocToDownload(document);
		const filename = fileName(document);
		setDocName(filename);
	};

	return (
		<MainSidebarContainer>
			<Grid
				container
				direction="column"
				sx={{
					width: '100%',
					height: '100%',
					padding: `${tablet ? '10px' : '5px 5px 5px 55px'}`,
				}}
			>
				<RootGrid container spacing={0.5} justifyContent="center" wrap="nowrap">
					<Grid item md={10} lg={10} sx={{ height: '100%' }}>
						<Box>
							<Box
								sx={{
									height: pages > 1 ? '80vh' : '90vh',
									width: '100%',
									marginLeft: 'auto',
									marginRight: 'auto',
									overflow: 'auto',
								}}
							>
								<Snackbar
									open={showDocUrlCopied}
									anchorOrigin={{
										vertical: 'top',
										horizontal: 'center',
									}}
									autoHideDuration={3000}
									onClose={() => setShowDocUrlCopied(false)}
								>
									<Alert
										onClose={() => setShowDocUrlCopied(false)}
										severity="success"
										sx={{ width: '100%' }}
									>
										Document URL copied!
									</Alert>
								</Snackbar>
								<CardsTable
									columns={columns}
									data={documents}
									onSort={handleSort}
									onRowClick={(row) =>
										mobile
											? initiateDocumentDownload(row.original)
											: redirectToSpecificDocument(row.original)
									}
									sortSource={ESortSource.DOCUMENTS}
								/>
								<NoDocuments documents={documents} />
							</Box>

							{pages > 1 ? (
								<Box
									sx={{
										width: '100%',
										textAlign: 'center',
									}}
								>
									<Pagination
										count={pages}
										variant="outlined"
										page={page + 1}
										onChange={(
											event: React.ChangeEvent<unknown>,
											value: number,
										) => setPage(value - 1)}
										sx={{
											'&.MuiPagination-root': {
												backgroundColor: 'unset !important',
												justifyContent: 'center',
												display: 'flex',
												boxShadow: 'none',
												fontFamily:
													'IBMPlexSansRegular, IBMPlexSansBold, IBMPlexSansLight',
												fontSize: 13,
											},
										}}
									/>
								</Box>
							) : (
								<></>
							)}
						</Box>
					</Grid>
					<DocumentFilters />
				</RootGrid>
				{deleteOpen && (
					<DeleteDocumentModal
						open={deleteOpen}
						handleSubmit={handleDeleteConfirm}
						handleClose={() => setDeleteOpen(false)}
					/>
				)}
			</Grid>
		</MainSidebarContainer>
	);
};
