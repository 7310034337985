import { IInvestorGrant, IUserWithInvestors } from 'api/redux/services/userApi';
import { ISortStrategy } from 'api/redux/types';
import { User } from 'app/types/user.types';
import { ChangeEventHandler, Dispatch, SetStateAction } from 'react';

export enum ColHeaders {
	Name = 'Name',
	Role = 'Role',
	Email = 'Email',
	Investors = 'Investors',
	LastLogin = 'Last Login',
	Actions = '',
}

export interface IColumn {
	Header: ColHeaders;
	accessor: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	Cell: ({ row }: { row: any }) => JSX.Element | null;
	isVisible?: boolean;
	width?: number;
	minWidth?: number;
}

export interface IUsersTableListProps {
	refetchUsers: () => void;
	columns: IColumn[];
	addSponsor: boolean;
	setAddSponsor: Dispatch<SetStateAction<boolean>>;
	onUserRowClick: (user: User) => void;
	editUserPermissions: boolean;
	setEditUserPermissions: Dispatch<SetStateAction<boolean>>;
	editInvestor: boolean;
	setEditInvestor: Dispatch<SetStateAction<boolean>>;
	deleteInvestor: boolean;
	setDeleteInvestor: Dispatch<SetStateAction<boolean>>;
	selectedInvestorId: number | undefined;
	setSelectedInvestorId: Dispatch<SetStateAction<number | undefined>>;
	selectedUser: IUserWithInvestors | undefined;
	investors: IInvestorGrant[];
	selectedUserId: number | undefined;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	pages: number;
	loading: boolean;
	searchString: string;
	setSearchString: Dispatch<SetStateAction<string>>;
}

export interface IUsersTableProps {
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	pages: number;
	refetchUsers: () => void;
	loading: boolean;
	searchString: string;
	setSearchString: Dispatch<SetStateAction<string>>;
}

export interface IAddUserButtonProps {
	setAddSponsor: Dispatch<SetStateAction<boolean>>;
	canAddUser: () => boolean;
}

export interface IUsersTableLoaderProps {
	refetchUsers: () => void;
	page: number;
	setPage: Dispatch<SetStateAction<number>>;
	loading: boolean;
	pages: number;
	children: (props: IUsersTableListProps) => JSX.Element;
	searchString: string;
	setSearchString: Dispatch<SetStateAction<string>>;
}

export interface IDeleteUserModal {
	userId: number | null;
	isOpen: boolean;
	handleClose: () => void;
	afterDelete: () => void;
}
