import {
	GrantUpdates,
	DropDownOrderValues,
	InitialSponsorStateParams,
	FundChangeParams,
	InvestorChangeParams,
	UserGrantsChangeParams,
	ShowAllUsersParams,
} from '../types';
import {
	sortByName,
	getFirstItemOrEmpty,
	getAllAvailableInvestors,
	getAvailableFundsForInvestorOrder,
} from './DropdownReducerHelpers';
import { loadFromCookies } from './CookieHelpers';

export const handleFundChange = ({
	selectedFund,
	currentState,
	emptyInvestor,
}: FundChangeParams): GrantUpdates => {
	const sortedInvestors = sortByName(selectedFund.investors);
	const isInvestorInNewFund = selectedFund.investors.some(
		(investor) => investor.id === currentState.currentInvestor.id,
	);

	return {
		currentFund: selectedFund,
		availableInvestors: sortedInvestors,
		currentInvestor: isInvestorInNewFund
			? currentState.currentInvestor
			: getFirstItemOrEmpty(sortedInvestors, emptyInvestor),
	};
};

export const handleInvestorChange = ({
	selectedInvestor,
	currentState,
	emptyFund,
}: InvestorChangeParams): GrantUpdates => {
	if (currentState.firstDropdown !== DropDownOrderValues.INVESTOR) {
		return { currentInvestor: selectedInvestor };
	}

	const availableFunds = getAvailableFundsForInvestorOrder(
		currentState.currentSponsor,
		selectedInvestor.id,
	);

	return {
		currentInvestor: selectedInvestor,
		availableFunds,
		currentFund: availableFunds.some(
			(fund) => fund.id === currentState.currentFund.id,
		)
			? currentState.currentFund
			: getFirstItemOrEmpty(availableFunds, emptyFund),
	};
};

export const getInitialSponsorState = ({
	sponsor,
	emptyFund,
	emptyInvestor,
	firstDropdown,
}: InitialSponsorStateParams): GrantUpdates => {
	const sortedFunds = sortByName(sponsor.funds);
	const firstFund = getFirstItemOrEmpty(sortedFunds, emptyFund);
	const sortedInvestors = sortByName(firstFund.investors);
	const firstInvestor = getFirstItemOrEmpty(sortedInvestors, emptyInvestor);
	const allAvailableInvestors = getAllAvailableInvestors(sponsor);

	if (firstDropdown === DropDownOrderValues.FUND) {
		return {
			currentSponsor: sponsor,
			currentFund: firstFund,
			currentInvestor: firstInvestor,
			allAvailableInvestors,
			availableFunds: sortedFunds,
			availableInvestors: sortedInvestors,
		};
	}

	const availableInvestors = sortByName(allAvailableInvestors);
	const investorFunds = sponsor.funds.filter((fund) =>
		fund.investors.some((investor) => investor.id === firstInvestor.id),
	);

	return {
		currentSponsor: sponsor,
		currentFund: firstFund,
		currentInvestor: firstInvestor,
		allAvailableInvestors,
		availableFunds: sortByName(investorFunds),
		availableInvestors,
	};
};

export const handleUserGrantsChange = ({
	userGrants,
	loadCookies,
	emptyInvestor,
}: UserGrantsChangeParams): GrantUpdates => {
	const sortedSponsors = sortByName(userGrants);

	if (!loadCookies) {
		return { availableSponsors: sortedSponsors };
	}

	const { sponsor, fund, investor, firstDropdown } =
		loadFromCookies(userGrants);

	if (!sponsor) {
		return {
			availableSponsors: sortedSponsors,
			firstDropdown,
		};
	}

	const sortedFunds = sortByName(sponsor.funds);
	const allAvailableInvestors = getAllAvailableInvestors(sponsor);

	if (!fund) {
		return {
			availableSponsors: sortedSponsors,
			currentSponsor: sponsor,
			availableFunds: sortedFunds,
			allAvailableInvestors,
			firstDropdown,
		};
	}

	const sortedInvestors = sortByName(fund.investors);
	const validInvestor =
		investor && sortedInvestors.some((inv) => inv.id === investor.id)
			? investor
			: getFirstItemOrEmpty(sortedInvestors, emptyInvestor);

	return {
		availableSponsors: sortedSponsors,
		currentSponsor: sponsor,
		currentFund: fund,
		currentInvestor: validInvestor,
		availableFunds: sortedFunds,
		availableInvestors: sortedInvestors,
		allAvailableInvestors,
		firstDropdown,
	};
};

export const handleShowAllUsers = ({
	showAll,
	currentState,
}: ShowAllUsersParams): GrantUpdates => ({
	availableInvestors: showAll
		? currentState.allAvailableInvestors
		: sortByName(currentState.currentFund.investors),
});
