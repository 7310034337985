import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import {
	GrantState,
	DropDownOrderValues,
	emptyFund,
	emptyInvestor,
	emptySponsor,
} from './types';
import { ISponsorGrant, IFundGrant, IInvestorGrant } from './services/userApi';
import { STORAGE_KEYS } from './constants/StorageKeys';
import {
	handleFundOrderChange,
	handleInvestorOrderChange,
	getInitialSponsorState,
	sortByName,
} from './helpers/DropdownReducerHelpers';
import {
	handleUserGrantsChange,
	handleShowAllUsers,
	handleFundChange,
	handleInvestorChange,
} from './helpers/StateHelpers';

const initialState: GrantState = {
	grants: {
		currentSponsor: emptySponsor,
		currentFund: emptyFund,
		currentInvestor: emptyInvestor,
		availableSponsors: [],
		availableFunds: [],
		availableInvestors: [],
		allAvailableInvestors: [],
		firstDropdown: DropDownOrderValues.FUND,
	},
	showAllUsers: false,
};

export const grantSlice = createSlice({
	name: 'grants',
	initialState,
	reducers: {
		onChangeSponsor: (state, action: PayloadAction<ISponsorGrant>) => {
			const initialState = getInitialSponsorState(
				action.payload,
				emptyFund,
				emptyInvestor,
				state.grants.firstDropdown,
			);
			Object.assign(state.grants, initialState);
		},

		onChangeFund: (state, action: PayloadAction<IFundGrant>) => {
			const updates = handleFundChange({
				selectedFund: action.payload,
				currentState: state.grants,
				emptyInvestor,
			});
			Object.assign(state.grants, updates);
		},

		onChangeInvestor: (state, action: PayloadAction<IInvestorGrant>) => {
			const updates = handleInvestorChange({
				selectedInvestor: action.payload,
				currentState: state.grants,
				emptyFund,
			});
			Object.assign(state.grants, updates);
		},

		onChangeUserGrants: (
			state,
			action: PayloadAction<{
				userGrants: ISponsorGrant[];
				loadCookies: boolean;
			}>,
		) => {
			const { userGrants, loadCookies } = action.payload;
			if (!loadCookies) {
				state.grants.availableSponsors = sortByName(userGrants);
				return;
			}

			const updates = handleUserGrantsChange({
				userGrants,
				loadCookies,
				emptyInvestor,
			});
			Object.assign(state.grants, updates);
		},

		onShowAllUsers: (state, action: PayloadAction<boolean>) => {
			const updates = handleShowAllUsers({
				showAll: action.payload,
				currentState: state.grants,
			});
			Object.assign(state.grants, updates);
			state.showAllUsers = action.payload;
		},

		onChangeDropdownOrder: (
			state,
			action: PayloadAction<DropDownOrderValues>,
		) => {
			const newOrder = action.payload;
			sessionStorage.setItem(STORAGE_KEYS.DROPDOWN.SESSION, newOrder);

			const updates =
				newOrder === DropDownOrderValues.FUND
					? handleFundOrderChange(state.grants)
					: handleInvestorOrderChange(state.grants);

			state.grants.firstDropdown = newOrder;
			Object.assign(state.grants, updates);
		},
	},
});

export const {
	onChangeSponsor,
	onChangeFund,
	onChangeInvestor,
	onChangeUserGrants,
	onShowAllUsers,
	onChangeDropdownOrder,
} = grantSlice.actions;

export const selectDropdown = ({ grants }: RootState): GrantState => grants;

export default grantSlice.reducer;
