import { getCookie } from 'common/helpers/Cookies';
import { STORAGE_KEYS } from '../constants/StorageKeys';
import { ISimpleGrant, DropDownOrderValues } from '../types';
import { ISponsorGrant } from '@services/userApi';
import { sortByName } from './DropdownReducerHelpers';

export const loadFromCookies = (
	availableSponsors: ISponsorGrant[],
): ISimpleGrant => {
	const sponsorIdSession = sessionStorage.getItem(STORAGE_KEYS.SPONSOR.SESSION);
	sessionStorage.removeItem(STORAGE_KEYS.SPONSOR.SESSION);

	const sponsorId = sponsorIdSession || getCookie(STORAGE_KEYS.SPONSOR.COOKIE);
	const fundId = getCookie(STORAGE_KEYS.FUND.COOKIE);
	const investorId = getCookie(STORAGE_KEYS.INVESTOR.COOKIE);
	const firstDropdownCookie = getCookie(STORAGE_KEYS.DROPDOWN.COOKIE);

	const firstDropdown =
		firstDropdownCookie === DropDownOrderValues.INVESTOR
			? DropDownOrderValues.INVESTOR
			: DropDownOrderValues.FUND;

	const sortedSponsors = sortByName(availableSponsors);
	let sponsor =
		sponsorId !== ''
			? sortedSponsors.find((s) => s.id === parseInt(sponsorId))
			: sortedSponsors[0];

	if (sponsor) {
		const sortedFunds = sortByName(sponsor.funds);
		const fund =
			fundId !== ''
				? sortedFunds.find((f) => f.id === parseInt(fundId))
				: sortedFunds[0];

		if (fund) {
			const sortedInvestors = sortByName(fund.investors);
			const investor =
				investorId !== ''
					? sortedInvestors.find((i) => i.id === parseInt(investorId))
					: sortedInvestors[0];

			return { sponsor, fund, investor, firstDropdown };
		}
		return { sponsor, firstDropdown };
	}

	return { firstDropdown };
};
