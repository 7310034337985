import { emptyInvestor, DropDownOrderValues } from 'api/redux/types';
import { IInvestorGrant } from 'api/redux/services/userApi';
import { ROLES } from 'common/helpers/permissions/Roles';
import { Dispatch } from 'redux';
import { onChangeInvestor } from 'api/redux/DropdownReducer';

export const sortByName = <T extends { name: string }>(
	collection: T[],
): T[] => {
	const sortedCollection = [...collection];
	return sortedCollection.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export function handleInvestorFundSync(
	isInvestorViewPage: boolean,
	currentFund: { id: number; investors: IInvestorGrant[] },
	currentInvestor: IInvestorGrant,
	dispatch: Dispatch,
): void {
	if (!isInvestorViewPage && currentFund.investors) {
		const sortedInvestors = sortByName(currentFund.investors);
		const investorInCurrentFund = currentFund.investors.some(
			(investor) => investor.id === currentInvestor.id,
		);

		if (!investorInCurrentFund && sortedInvestors.length > 0) {
			dispatch(onChangeInvestor(sortedInvestors[0]));
		}
	}
}

export function getAllowedInvestors(
	isInvestorViewPage: boolean,
	showAllUsers: boolean,
	firstDropdown: string,
	availableInvestors: IInvestorGrant[],
	allAvailableInvestors: IInvestorGrant[],
	currentFund: { id: number; investors: IInvestorGrant[] },
): IInvestorGrant[] {
	const getInvestorsForFund = () =>
		availableInvestors.filter((investor) =>
			currentFund.investors.some(
				(fundInvestor) => fundInvestor.id === investor.id,
			),
		);

	const scenarios = {
		viewAllUsers: isInvestorViewPage && showAllUsers,
		activeFund:
			firstDropdown === DropDownOrderValues.FUND && currentFund.id !== 0,
		investorDropdown: firstDropdown === DropDownOrderValues.INVESTOR,
	};

	const investorSelections = {
		viewAllUsers: allAvailableInvestors,
		activeFund: getInvestorsForFund(),
		investorDropdown: allAvailableInvestors,
		default: availableInvestors,
	};

	const activeScenario =
		(Object.keys(scenarios).find(
			(key) => scenarios[key as keyof typeof scenarios],
		) as keyof typeof investorSelections) || 'default';

	return investorSelections[activeScenario];
}

export function handleInvestorViewPageLogic(
	allAvailableInvestors: IInvestorGrant[],
	currentInvestor: IInvestorGrant,
	isAdmin: boolean,
	dispatch: Dispatch,
): IInvestorGrant[] {
	const filteredInvestors = allAvailableInvestors.filter(
		(investor) => investor.role === ROLES.INVESTOR_ADMIN || isAdmin,
	);

	if (currentInvestor.role === ROLES.INVESTOR_USER) {
		const firstAdminInvestor = filteredInvestors[0] || emptyInvestor;
		dispatch(onChangeInvestor(firstAdminInvestor));
	}

	return filteredInvestors;
}
