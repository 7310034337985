import { useState } from 'react';
import { Document, Page } from 'react-pdf';

export const FramedDocument = ({ pdfBlob }) => {
	const [numPages, setNumPages] = useState<number | undefined>(undefined);
	const [pageWidth, setPageWidth] = useState<number | undefined>(undefined);

	const onDocumentLoadSuccess = async (pdf) => {
		const page = await pdf.getPage(1);
		const width: number = page.view[2];
		/* 1st condition handles when pdf would overlap metadata or create horizontal scrollbars
		 * 2nd is when in tablet state
		 * 3rd is default */
		if (
			width > 800 &&
			window.innerWidth < 1300 &&
			width < window.innerWidth - 275
		) {
			setPageWidth(775);
		} else if (width > window.innerWidth - 275) {
			setPageWidth(window.innerWidth - 275);
		} else {
			setPageWidth(width);
		}
		setNumPages(pdf.numPages);
	};

	return (
		<Document file={pdfBlob} onLoadSuccess={onDocumentLoadSuccess}>
			{Array.from(new Array(numPages), (el, index) => (
				<Page
					className="pdf-page"
					error={'Failed to load PDF'}
					key={`page_${index + 1}`}
					width={pageWidth}
					pageNumber={index + 1}
				/>
			))}
		</Document>
	);
};
