import { FC, useEffect } from 'react';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { InvestorInfo } from './investor-info/InvestorInfo';
import { InvestorMembers } from './InvestorsMembers';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { onShowAllUsers, selectDropdown } from 'api/redux/DropdownReducer';
import { useGrants } from 'common/helpers/permissions/use-grants/useGrants';
import { ROLES } from 'common/helpers/permissions/Roles';
import { useDispatch } from 'react-redux';

const InvestorInfoWrapper: FC = () => {
	const { showAllUsers } = useSelector(selectDropdown);

	if (showAllUsers) return <></>;

	return (
		<PermissionsWrapper scopes={[SCOPES.canSeeInvestorInfo]}>
			<InvestorInfo />
		</PermissionsWrapper>
	);
};

export const InvestorView: FC = () => {
	const { currentRoles } = useGrants();
	const dispatch = useDispatch();
	const { showAllUsers } = useSelector(selectDropdown);

	useEffect(() => {
		if (currentRoles.includes(ROLES.INVESTOR_ADMIN) && showAllUsers) {
			dispatch(onShowAllUsers(false));
		}
	}, [currentRoles]);

	return (
		<MainSidebarContainer>
			<Grid
				container
				spacing={1}
				sx={{
					height: '100%',
					margin: '5px',
					width: '100%',
				}}
			>
				<InvestorInfoWrapper />
				<InvestorMembers />
			</Grid>
		</MainSidebarContainer>
	);
};
