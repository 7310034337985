import { GrantState } from '@redux/types';
import { IFundGrant, IInvestorGrant, ISponsorGrant } from '@services/userApi';
import { DropDownOrderValues } from 'api/redux/types';

export const sortByName = <T extends { name: string }>(
	collection: T[],
): T[] => {
	const sortedCollection = [...collection];
	return sortedCollection.sort((a, b) => (a.name < b.name ? -1 : 1));
};

export const getFirstItemOrEmpty = <T>(items: T[], emptyItem: T): T => {
	return items.length ? items[0] : emptyItem;
};

export const handleFundOrderChange = (grants: GrantState['grants']) => {
	return {
		availableFunds: getAvailableFundsForFundOrder(grants),
		currentInvestor: setCurrentInvestor(grants),
		availableInvestors: getAvailableInvestorsForFundOrder(grants),
	};
};

export const handleInvestorOrderChange = (grants: GrantState['grants']) => {
	const allAvailableInvestors = getAllAvailableInvestors(grants.currentSponsor);
	const availableFunds = getAvailableFundsForInvestorOrder(
		grants.currentSponsor,
		grants.currentInvestor.id,
	);

	return {
		allAvailableInvestors,
		availableInvestors: sortByName(allAvailableInvestors),
		availableFunds,
		currentFund: setCurrentFund(
			grants.currentFund.id,
			availableFunds,
			grants.currentFund,
		),
	};
};

export const getAllAvailableInvestors = (
	sponsor: ISponsorGrant,
): IInvestorGrant[] => {
	const investors = sponsor.funds.reduce((acc: IInvestorGrant[], fund) => {
		fund.investors?.forEach((investor) => {
			if (!acc.find((inv) => inv.id === investor.id)) {
				acc.push(investor);
			}
		});
		return acc;
	}, []);

	return sortByName(investors);
};

export const getInitialSponsorState = (
	selectedSponsor: ISponsorGrant,
	emptyFund: IFundGrant,
	emptyInvestor: IInvestorGrant,
	firstDropdown: DropDownOrderValues,
) => {
	const sortedFunds = sortByName(selectedSponsor.funds);
	const firstFund = getFirstItemOrEmpty(sortedFunds, emptyFund);
	const sortedInvestors = sortByName(firstFund.investors);
	const firstInvestor = getFirstItemOrEmpty(sortedInvestors, emptyInvestor);
	const allAvailableInvestors = getAllAvailableInvestors(selectedSponsor);

	if (firstDropdown === DropDownOrderValues.FUND) {
		return {
			currentSponsor: selectedSponsor,
			currentFund: firstFund,
			currentInvestor: firstInvestor,
			allAvailableInvestors,
			availableFunds: sortedFunds,
			availableInvestors: sortedInvestors,
		};
	}

	const availableInvestors = sortByName(allAvailableInvestors);
	const investorFunds = selectedSponsor.funds.filter((fund) =>
		fund.investors.some((investor) => investor.id === firstInvestor.id),
	);

	return {
		currentSponsor: selectedSponsor,
		currentFund: firstFund,
		currentInvestor: firstInvestor,
		allAvailableInvestors,
		availableFunds: sortByName(investorFunds),
		availableInvestors,
	};
};

export const getAvailableFundsForFundOrder = (grants: GrantState['grants']) => {
	return sortByName(grants.currentSponsor.funds);
};

export const getAvailableInvestorsForFundOrder = (
	grants: GrantState['grants'],
) => {
	return sortByName(grants.currentFund.investors);
};

export const setCurrentInvestor = (grants: GrantState['grants']) => {
	const currentInvestor = grants.currentFund.investors.find(
		(investor) => investor.id === grants.currentInvestor.id,
	);
	return (
		currentInvestor ||
		getFirstItemOrEmpty(grants.currentFund.investors, grants.currentInvestor)
	);
};

export const getAvailableFundsForInvestorOrder = (
	currentSponsor: ISponsorGrant,
	currentInvestorId: number,
): IFundGrant[] => {
	const investorFunds = currentSponsor.funds.filter((fund) =>
		fund.investors.some((investor) => investor.id === currentInvestorId),
	);
	return sortByName(investorFunds);
};

export const setCurrentFund = (
	currentFundId: number,
	availableFunds: IFundGrant[],
	defaultFund: IFundGrant,
): IFundGrant => {
	const currentFund = availableFunds.find((fund) => fund.id === currentFundId);
	return currentFund || getFirstItemOrEmpty(availableFunds, defaultFund);
};
