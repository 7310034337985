import React, { useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import { Autorenew } from '@mui/icons-material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { IInvestorGrant } from '@services/userApi';

interface SwitchOrderButtonProps {
	onClick?: () => void;
	availableInvestors: IInvestorGrant[];
}

const SwitchOrderButtonBox = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: theme.spacing(5),
	height: theme.spacing(5),
	backgroundColor: 'transparent',
	margin: theme.spacing(0.5),
	cursor: 'pointer',
	boxSizing: 'border-box',
	transition: 'transform 0.2s ease-in-out',
	'&:hover': {
		transform: 'scale(1.2)',
	},
}));

export const SwitchOrderButton: React.FC<SwitchOrderButtonProps> = ({
	onClick,
	availableInvestors,
}) => {
	const [rotation, setRotation] = useState(0);
	const theme = useTheme();

	if (availableInvestors.length === 0) return <></>;

	const handleClick = () => {
		setRotation((prevRotation) => prevRotation + 180);
		if (onClick) {
			onClick();
		}
	};

	return (
		<Tooltip title="Switch Order of Dropdowns" placement="top" arrow>
			<SwitchOrderButtonBox onClick={handleClick}>
				<Autorenew
					fontSize="small"
					style={{
						color: '#F1F2F9',
						transform: `rotate(${rotation}deg)`,
						fontSize: theme.spacing(3),
						transition: 'transform 0.2s ease-in-out',
					}}
				/>
			</SwitchOrderButtonBox>
		</Tooltip>
	);
};
