import { FC } from 'react';
import { Badge, Box, styled } from '@mui/material';

const StyledSVG = styled('svg')(() => ({
	height: '27px',
	width: '27px',
	cursor: 'pointer',
}));

export const DropdownOpenIcon: FC = () => (
	<StyledSVG
		focusable="false"
		viewBox="0 0 24 24"
		data-testid="ArrowDropUpIcon"
	>
		<path
			d="M18.0095 14.976L12.9856 10L8.00964 15.0239L18.0095 14.976Z"
			fill="currentColor"
		/>
	</StyledSVG>
);

export const DropdownClosedIcon: FC = () => (
	<StyledSVG
		focusable="false"
		viewBox="0 0 24 24"
		data-testid="ArrowDropDownIcon"
	>
		<path d="M7 10l5 5 5-5z" fill="currentColor" />
	</StyledSVG>
);

const MenuBox = styled(Box)(() => ({
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	alignSelf: 'center',
	marginLeft: '2px',
}));

interface MenuIconProps {
	isMenuOpen: boolean;
}

export const MenuIcon: FC<MenuIconProps> = ({ isMenuOpen }) => (
	<MenuBox>
		<Badge color="secondary">
			{isMenuOpen ? <DropdownOpenIcon /> : <DropdownClosedIcon />}
		</Badge>
	</MenuBox>
);
