import { FC } from 'react';
import { INoDocumentsProps } from './types';
import { Box, Typography } from '@mui/material';

export const NoDocuments: FC<INoDocumentsProps> = ({ documents }) => {
	if (documents.length) return <></>;

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				paddingTop: '10vh',
			}}
		>
			<img
				src="/no-documents.png"
				width="128"
				height="128"
				alt="no documents available"
			/>
			<Typography>You do not have any available documents</Typography>
		</Box>
	);
};
