import moment from 'moment';
import {
	DocumentTableKeys,
	IDownloadFileParams,
	IPDFWatermarkParams,
	IspecificDocumentUrlParams,
} from './types';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { Document } from 'app/types/document.types';
import { documentTableKeys } from './types';
import { DocumentTables } from './types';
import { degrees, PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { User } from '@auth0/auth0-react';
import printJS from 'print-js-safari';

export const getDate = (stringDate: string): Date => {
	return moment(stringDate).toDate();
};
export const formatDate = (stringDate: string): string => {
	return moment(stringDate).format('MM/DD/YYYY');
};

export const specificDocumentUrl = ({
	document,
	withBase,
	tableKey,
	investorId,
}: IspecificDocumentUrlParams): string => {
	const host = window.location.hostname;
	const path = `/documents/${document.id}`;
	const queryString = `?t=${tableKey}&S=${
		document.organizationId || document.fund?.organizationId
	}&F=${document.fundId || document.fund?.id}${
		tableKey === DocumentTableKeys.FT
			? `&I=${investorId}`
			: '&I=' + (document.investorId || document.investor?.id)
	}`;

	if (!withBase) return `${path}${queryString}`;

	return `${host}${path}${queryString}`;
};

export const downloadFile = ({
	fileUrl,
	fileName,
}: IDownloadFileParams): void => {
	axios
		.get(fileUrl, {
			responseType: 'blob',
		})
		.then((res) => {
			fileDownload(res.data, fileName);
		});
};

export const fileName = (document: Document): string => {
	const type = document.documentType?.name || document.type;
	const level: DocumentTableKeys =
		documentTableKeys[document.table as DocumentTables];
	const investorName =
		level === DocumentTableKeys.IT
			? document.investor?.name || document.investorName
			: '';
	const fundName = document.fund?.name || document.fundName;

	if (level === DocumentTableKeys.IT)
		return `${type} - ${investorName} - ${fundName}.pdf`;

	return `${type} - ${fundName}.pdf`;
};

export const pdfAddWaterMark = async ({
	url,
	userEmail = '',
}: IPDFWatermarkParams): Promise<Uint8Array> => {
	const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
	const pdfDoc = await PDFDocument.load(existingPdfBytes);
	const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
	const pages = pdfDoc.getPages();
	const dateNow = moment().format('MM/DD/YYYY');

	for (let i = 0; i < pages.length; i++) {
		const page = pages[i];

		const { height } = page.getSize();
		page.drawText(`${userEmail} - ${dateNow}`, {
			x: 100,
			y: height / 2 - 150,
			size: 30,
			font: helveticaFont,
			color: rgb(0.847, 0.863, 0.839),
			rotate: degrees(45),
			opacity: 0.5,
		});
	}

	return await pdfDoc.save();
};

export const urlForWaterMarkedDoc = async (
	signedUrl: string,
	user?: User,
): Promise<string> => {
	const pdf: Uint8Array = await pdfAddWaterMark({
		url: signedUrl,
		userEmail: user?.email,
	});
	const pdfFile = new Blob([pdf], { type: 'application/pdf' });
	return URL.createObjectURL(pdfFile);
};

export const getBlobUrl = async (fileUrl: string): Promise<string> => {
	const res = await axios.get(fileUrl, {
		responseType: 'blob',
	});
	return URL.createObjectURL(res.data);
};

export const printWatermarkedDoc = async (
	signedUrl: string,
	user?: User,
): Promise<void> => {
	const fileUrl = await urlForWaterMarkedDoc(signedUrl, user);

	printJS({ printable: fileUrl });
};
