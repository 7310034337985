import { FC } from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { ICapitalAccountStatementHeader } from './types';
import { HeaderOptions } from './HeaderOptions';
import { EdgeCaseMessage } from './EdgeCaseMessage';
import { useSelector } from 'react-redux';
import { selectNavbarMobile } from 'api/redux/NavBarStateReducer';
import { styled } from '@mui/system';

const HeaderPaper = styled(Paper)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexGrow: 4,
	height: '7vh',
	padding: '5px',
	overflow: 'hidden',
	border: '1px solid #D0D7DE',
	alignSelf: 'flex-start',
}));

const HeaderBox = styled(Box)(() => ({
	width: 'min(100%, 1200px)',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexGrow: 2,
	paddingBottom: '5px',
}));

const HeaderText = styled(Typography)(() => ({
	paddingLeft: '12px',
}));

export const CapitalAccountStatementHeader: FC<ICapitalAccountStatementHeader> =
	({
		setError,
		currentReportingPeriod,
		capitalActivityData,
		fundCapitalActivityData,
		loadCapitalActivity,
		investorAllowedReportTypes,
		afterDelete,
		hasPermission,
	}) => {
		const mobile = useSelector(selectNavbarMobile);

		return (
			<HeaderBox>
				<HeaderPaper elevation={0}>
					{mobile ? (
						<></>
					) : (
						<HeaderText variant="h4">Capital Account Statement</HeaderText>
					)}
					<HeaderOptions
						loadCapitalActivity={loadCapitalActivity}
						currentReportingPeriod={currentReportingPeriod}
						setError={setError}
						capitalActivityData={capitalActivityData}
						fundCapitalActivityData={fundCapitalActivityData}
						afterDelete={afterDelete}
						hasPermission={hasPermission}
					/>
					<EdgeCaseMessage
						investorAllowedReportTypes={investorAllowedReportTypes}
						capitalActivityData={capitalActivityData}
					/>
				</HeaderPaper>
			</HeaderBox>
		);
	};
