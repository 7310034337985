import { FC, useCallback, useMemo } from 'react';
import { MenuItem, InputLabel, SelectChangeEvent } from '@mui/material';
import { onChangeFund, selectDropdown } from 'api/redux/DropdownReducer';
import { DropdownFormControl, SelectControl } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { ERoutes } from 'App';
import { useLocation } from 'react-router-dom';
import { onSetSelectedDocTypeIds } from 'api/redux/DocumentsReducer';
import { AllUsersButton } from './AllUsersButton';

export const FundDropdown: FC = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();

	const grants = useSelector(selectDropdown);
	const { availableFunds, currentFund, currentSponsor } = grants.grants;

	const isInvestorViewPage = useMemo(
		() =>
			[ERoutes.OrganizationView, ERoutes.InvestorsView].includes(
				pathname as ERoutes,
			),
		[pathname],
	);

	const handleFundChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			const selectedFundId = Number(event.target.value);
			const selectedFund = availableFunds.find(
				(fund) => fund.id === selectedFundId,
			);
			if (selectedFund) {
				dispatch(onChangeFund(selectedFund));
				dispatch(onSetSelectedDocTypeIds([]));
			}
		},
		[availableFunds, dispatch],
	);

	const DynamicFunds: FC = () => {
		return (
			<DropdownFormControl
				variant="outlined"
				size="small"
				style={{ margin: 5, width: '300px' }}
				title={currentFund.name}
			>
				<InputLabel id="fund-selector" style={{ color: '#F1F2F9' }}>
					Fund
				</InputLabel>
				<SelectControl
					labelId="fund-selector"
					id="fund-selector"
					value={currentFund?.id || ''}
					onChange={handleFundChange}
					label="Fund"
				>
					{availableFunds.map((fund) => (
						<MenuItem key={fund.id} value={fund.id.toString()}>
							{fund.name}
						</MenuItem>
					))}
				</SelectControl>
			</DropdownFormControl>
		);
	};

	if (
		currentSponsor.id !== 0 &&
		availableFunds.length > 0 &&
		!isInvestorViewPage
	) {
		return <DynamicFunds />;
	}

	if (isInvestorViewPage) {
		return <AllUsersButton />;
	}

	return <></>;
};
